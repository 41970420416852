<template>
	<div>
		<section class="hero is-dark is-small">
			<div class="hero-body">
				<div class="container">
					<h2 class="subtitle">
						누군가에겐 편리한 소소한 도구들
					</h2>
				</div>
			</div>
		</section>
		<div class="container has-text-centered" id="tool_list">
			<br>
			<div class="columns is-multiline is-centered">
				<div class="column is-half" :key="index" v-for="(tool, index) in Tools">
					<router-link class="box" :to="tool.href">
						<h2 class="subtitle has-text-weight-bold">{{ tool.name }}</h2>
						<p class="description" v-html="tool.description"></p>
					</router-link>
				</div>
			</div>
			<Adsense
                data-ad-client="ca-pub-8959064465854631"
                data-ad-slot="1234567890">
            </Adsense>
            <br>
            <br>
            <br>
			<router-link to="/contact">ESukmean에게 연락하기</router-link>
		</div>
		<br>
	</div>
</template>
<style>
#tool_list div.box:hover{
	box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px #7957d5
}

.description {
	line-height: 1.2;
	padding-bottom: 8px
}
</style>
<script>
export default {
	created () {
		document.title = "ESukmean's - 도구";
	},

	data() {
		return{
			Tools: [
				{ 
					name: '글 섞기',
					description: '글 / 문장 / 단어를 섞어주는 도구<br>시험문제 출제나 그 외 단어를 섞으려 할 때 유용',
					href: '/tools/mixString',
				},
				{
					name: 'SSL 인증서 만료일 확인기',
					description: '내가 관리하는 서버의 SSL 인증서의 유효기간은 몇일 남았을까?<br>서버 SSL 인증서의 만료일을 확인 할 수 있는 도구',
					href: '/tools/ssl-certification-expire-date',
				},
				// {
				// 	name: '내신 등수 계산기',
				// 	description: '몇 명 까지가 내신 2등급일까?<br>학생수만 입력해 주면 몇등까지가 내신 n등급인지 보여주는 도구',
				// 	href: '/tools/naesin',
				// }
			],
		} 
	}
}
</script>
